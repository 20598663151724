<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">
        Providus Rename
      </h1>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <p class="text-red-800" v-if="errorMssg">
            {{ errorMssg }}
          </p>
        </div>

        <div class="w-1/2 xl:my-4 xl:px-4">
          <div class="w-full p-5 border-2 border-gray-100 bg-white rounded-lg">
            <div>
              <form
                @submit.prevent="renameProvidus"
                class="flex flex-wrap xl:-mx-4  pb-5"
              >
                <!-- <div class="w-full"> -->
                <div class="flex w-full">
                  <div class="m-3 w-full">
                    <p class="text-xs font-bold mb-3">
                      Account Number
                    </p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="inline-block p-3 ring-1 w-11/12 outline-none ring-gray-300 rounded-sm w-80 text-sm"
                        name=""
                        placeholder="Enter Account Number"
                        id=""
                        v-model="accountNumber"
                        required
                      />
                    </p>
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="m-3 w-full">
                    <p class="text-xs font-bold mb-3">
                      Account Name
                    </p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="inline-block p-3 ring-1 w-11/12 outline-none ring-gray-300 rounded-sm w-80 text-sm"
                        name=""
                        placeholder="Enter Account Name"
                        id=""
                        v-model="accountName"
                        required
                      />
                    </p>
                  </div>
                </div>
                <div class="w-full flex mt-10">
                  <div class="m-3 w-80">
                    <button
                      class="py-3 mr-5 px-10 bg-yellow-300 rounded-md font-semibold text-black text-sm"
                      type="reset"
                    >
                      Reset
                    </button>
                    <button
                      class="py-3 px-10 bg-blue-800 rounded-md font-semibold text-white text-sm"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <!-- </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
          <p>{{ accountName }} was changed successfully.</p>
          <div class="flex">
            <button
              @click="alertDiv = false"
              class="
                        float-right
                        uppercase
                        shadow
                        mx-auto
                        hover:bg-red-600
                        bg-red-900
                        focus:shadow-outline
                        focus:outline-none
                        text-white text-xs
                        font-bold
                        p-3
                        rounded
                        inline
                      "
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      errorMssg: "",
      payload: {},
      alertDiv: false,
      message: "",
      accountName: "",
      accountNumber: "",
    };
  },
  async mounted() {},
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async renameProvidus() {
      //   if (
      //     (this.accountName !== "" && this.accountNumber === "") ||
      //     (this.accountName === "" && this.accountNumber !== "")
      //   ) {
      this.payload = {
        account_name: this.accountName,
        account_number: this.accountNumber,
      };
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("PROVIDUS_RENAME", this.payload);
        if (res.status) {
          this.accountName = "";
          this.accountNumber = "";
          alert(res.message);
        } else {
          alert(res.message);
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
      //   }
    },
  },
};
</script>

<style scoped>
.tel::-webkit-inner-spin-button,
.tel::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.tel {
  -moz-appearance: textfield;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
